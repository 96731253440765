<template>
  <b-overlay
    :show="loading"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <div class="quizzes">
      <div
        v-if="canOpenNewQuiz"
        class="buttons justify-content-start mt-0 mb-1"
      >
        <div
          v-if="canOpenNewQuiz"
          class="button primary sm"
          @click="startQuiz"
        >
          Iniciar questionário
        </div>
      </div>
      <div
        v-if="generating || generated || error || newPdfMessage || newPdfMessageError"
        class="feedback-row"
      >
        <div
          v-if="newPdfMessage"
          class="error-success"
        >
          {{ newPdfMessage }}
        </div>
        <div
          v-if="newPdfMessageError"
          class="error-success"
        >
          Ocorreu um erro. Por favor tente mais tarde.
        </div>
        <div
          v-if="generating"
          class="error-success"
        >
          Aguarde enquanto o seu relatório está a ser gerado.
          Será redirecionado para os resultados dentro de instantes.
        </div>
        <div
          v-if="generated"
          class="error-success"
        >
          O seu relatório foi gerado com sucesso!
        </div>
        <div
          v-if="error"
          class="error-danger"
        >
          Ocorreu um erro. Por favor tente mais tarde.
        </div>
      </div>
      <div
        v-if="mutableQuizzes && mutableQuizzes.length > 0"
        class="quizzes-list"
      >
        <div
          v-for="(quiz, index) in mutableQuizzes.slice(perPage*(currentPage-1),perPage*(currentPage))"
          :key="'quiz_'+index"
          class="quiz-info"
        >
          <div class="quiz-header">
            <span class="id">Questionário #{{ mutableQuizzes.length - index }}</span>
            <span class="status">
              <span>Estado</span>
              <span
                class="description"
                :class="status[getStatus(quiz.quiz)].color"
              >
                <b-icon
                  :icon="status[getStatus(quiz.quiz)].icon"
                />
                {{ status[getStatus(quiz.quiz)].label }}
              </span>
            </span>
          </div>
          <div class="quiz-details">
            <span class="answers">Respostas dadas: {{ quiz.responses_count }}/{{ quiz.total }}</span>
            <span class="datetime">Iniciado em: {{ quiz.quiz.created_at }}</span>
            <span
              v-if="quiz.quiz.status !== 'open'"
              class="datetime"
            >Finalizado em: {{ quiz.quiz.updated_at }}</span>
          </div>
          <div class="quiz-footer">
            <div
              v-if="devOrUat"
              class="quiz-consultor"
            >
              <b-row>
                <b-col cols="12">
                  <div
                    v-if="quiz.quiz.name || quiz.quiz.state || quiz.quiz.result"
                    class="info"
                  >
                    <div
                      v-if="quiz.quiz.name && !(quiz.quiz.state == 3 || quiz.quiz.result == 2)"
                    >
                      Consultor: {{ quiz.quiz.state == 2
                        ? quiz.quiz.name ? quiz.quiz.name : 'Não disponível'
                        : `A aguardar resposta de ${quiz.quiz.name ? quiz.quiz.name : 'Não disponível'}...` }}
                    </div>
                    <div
                      v-if="quiz.quiz.state == 3"
                    >
                      O facilitator requerido não aceitou o pedido.
                    </div>
                    <div
                      v-else-if="quiz.quiz.result == 2"
                    >
                      O inquérito não foi aprovado pelo faciliatador.
                    </div>
                    <div
                      v-if="quiz.quiz.result == 1"
                    >
                      O inquérito foi aprovado pelo facilitador.
                    </div>
                  </div>
                  <div
                    v-if="!quiz.quiz.name || quiz.quiz.state == 3 || quiz.quiz.result == 2"
                    class="buttons justify-content-start"
                  >
                    <div
                      class="button outlined sm"
                      @click="setConsultor(quiz.quiz.id)"
                    >
                      <b-icon icon="person-check" />
                      {{ (!quiz.quiz.name || quiz.quiz.state == 3 || quiz.quiz.result == 2) ?
                        'Adicionar facilitador' : 'Alterar facilitador' }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <!-- <div
                v-if="quiz.quiz.result == 1 && quiz.quiz.status == 'submitted'"
              >
                <div
                  class="button outlined"
                  @click="generatePdf(quiz.quiz.id)"
                >
                  <b-icon icon="check2-circle" /> Gerar relatório com selo de aprovação
                </div>
              </div> -->
            </div>
            <b-row>
              <b-col cols="12">
                <div class="buttons">
                  <div
                    v-if="quiz.quiz.status === 'open'"
                    class="button outlined sm"
                    @click="() => openFuture(quiz.quiz.id)"
                  >
                    <b-icon icon="check2-circle" /> Submeter questionário
                  </div>

                  <div
                    v-if="quiz.quiz.report !== null"
                    class="button primary sm"
                    @click="downloadReport(quiz.quiz.report, quiz.quiz.filename)"
                  >
                    <b-icon icon="eye" /> Ver Relatório Facilitador
                  </div>

                  <div
                    v-if="quiz.quiz.status === 'submitted'"
                    class="button outlined sm"
                    @click="goToQuizDisabled(quiz.quiz.id)"
                  >
                    <b-icon icon="ui-checks" /> Consultar questionário
                  </div>

                  <div
                    v-if="quiz.quiz.status === 'open'"
                    class="button primary sm"
                    @click="goToQuiz(quiz.quiz.id)"
                  >
                    <b-icon icon="arrow-right-circle" /> Continuar a responder
                  </div>
                  <div
                    v-if="quiz.quiz.status === 'submitted'"
                    class="button primary sm"
                    @click="goToReport(quiz.quiz.id)"
                  >
                    <b-icon icon="file-earmark-break" /> Consultar relatório
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-pagination
        v-if="mutableQuizzes && mutableQuizzes.length > 0"
        v-model="currentPage"
        class="fs-12"
        :total-rows="mutableQuizzes.length"
        :per-page="perPage"
      />
      <c-modal-consultor
        :quiz="quiz"
      />
      <b-modal
        id="future"
        hide-footer
        size="xl"
        centered
        header-class="border-bottom-0"
        footer-class="border-top-0"
      >
        <div
          v-if="showCleanedDim"
          class="feedback-row"
        >
          <div
            v-if="showCleanedDim"
          >
            <div
              v-if="showCleanedDim"
              class="error-warning"
            >
              <div v-if="showCleanedDim2 && showCleanedDim4">
                As dimensões "Infraestrutura Inteligente" e "Produtos Inteligentes" serão excluídas do cálculo por não possuírem respostas.
              </div>
              <div v-if="showCleanedDim2 && !showCleanedDim4">
                A dimensão "Infraestrutura Inteligente" será excluída do cálculo por não possuir respostas.
              </div>
              <div v-if="!showCleanedDim2 && showCleanedDim4">
                A dimensão "Produtos Inteligentes" será excluída do cálculo por não possuir respostas.
              </div>
            </div>
            <div
              v-if="showCleanedDim"
              class="buttons mb-0"
            >
              <div
                class="button primary mb-0"
                @click="() => openFuture(quiz, true)"
              >
                Submeter Questionário
              </div>
              <div
                class="button secondary mb-0"
                @click="goToQuiz(quiz)"
              >
                Consultar Questionário
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="report"
        >
          <div
            v-if="global"
            class="report-dimensions-classification"
          >
            <div class="title">
              Nas 6 dimensões analisadas, os níves de maturidade i4.0 são os seguintes:
            </div>
            <div class="row-dimensions-classification">
              <div class="dimensions">
                <div
                  class="row-dimensions header"
                >
                  <div class="dimension">
                    Dimensão
                  </div>
                  <div class="classification">
                    Nível
                  </div>
                </div>
                <div
                  v-for="(dim, j) in Object.keys(dimensions)"
                  :key="j"
                  class="row-dimensions"
                >
                  <div class="dimension">
                    {{ dimensions[dim].name }}
                  </div>
                  <div class="classification">
                    {{ dimensionsResult[j] }}
                  </div>
                </div>
                <div
                  class="row-dimensions"
                >
                  <div class="dimension">
                    Avaliação
                  </div>
                  <div
                    class="classification bg-primary text-light"
                    style="border-top-left-radius: 5px; border-bottom-left-radius: 5px; border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                  >
                    {{ global ? Math.floor(global[0].classification) : null }}
                  </div>
                </div>
              </div>

              <div class="classifications">
                <img
                  :src="require(`@/assets/N${Math.floor(global[0].classification)}.svg`)"
                >
              </div>
            </div>
          </div>
          <div
            v-if="svgResult && dimensionsResult"
            class="report-themes-classification"
          >
            <div class="title">
              Na avaliação dos temas, de cada dimensão, obteve as seguintes classificações:
            </div>
            <div class="row-themes-classification">
              <div
                class="svg"
                v-html="svgResult"
              />

              <div class="table">
                <div class="row-dimensions">
                  <div class="dimension">
                    Dimensão
                  </div>
                  <div class="themes">
                    <div class="row-themes">
                      <div
                        class="theme"
                      >
                        Tema
                      </div>
                      <div
                        class="classification"
                      >
                        Nível
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(dim, j) in Object.keys(dimensions)"
                  :key="`dimension-${j}`"
                  class="row-dimensions"
                >
                  <div
                    class="dimension"
                  >
                    {{ dimensions[dim].name }}
                  </div>
                  <div
                    class="themes"
                  >
                    <div
                      v-for="(theme, i) in Object.keys(themes.filter(e => e.dimension_id == dimensions[dim].id))"
                      :key="`theme-${i}`"
                      class="row-themes"
                    >
                      <div
                        class="theme"
                      >
                        {{ themes.filter(e => e.dimension_id == dimensions[dim].id)[theme].name }}
                      </div>
                      <div
                        class="classification"
                      >
                        {{ themesResult[themes.indexOf(themes
                          .filter(e => e.dimension_id == dimensions[dim].id)[theme])] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <c-level-group
            :on-click="getChoice"
            question="Selecione os seus objetivos futuros"
            :choices="choices"
            :max="Math.max(...choices[0].value.value)"
            :min="Math.min(...choices[0].value.value)"
            :selected="selected"
            :not-enabled="false"
          /> -->
          <c-radio-box-group
            question="Selecione os seus objetivos futuros"
            :choices="choices"
            :on-click="getChoice"
            :selected="selected"
            :not-enabled="false"
          />
          <div class="buttons">
            <div
              v-if="$store.getters.user.permission_id == 3 || $store.getters.user.permission_id == 1"
              class="button primary"
              @click="submitQuiz(quiz)"
            >
              Submeter
            </div>
            <div
              v-if="$store.getters.user.permission_id == 2"
              class="feedback-row"
            >
              <div
                class="error-danger"
              >
                Não possui permissões para submeter o questionário!
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  get, post, put,
} from '../../services/api';

import ModalConsultor from './ModalConsultor.vue';
// import LevelGroup from './LevelGroup.vue';
import RadioBoxGroup from './RadioBoxGroup.vue';
import { generateChartSite } from '../../services/common/generate-chart-site';

export default {
  name: 'DasboardQuizzes',
  components: {
    'c-modal-consultor': ModalConsultor,
    // 'c-level-group': LevelGroup,
    'c-radio-box-group': RadioBoxGroup,
  },
  props: {
    setState: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      devOrUat: false,
      canOpenNewQuiz: false,
      mutableQuizzes: null,
      currentPage: 1,
      perPage: 5,
      status: {
        open: { icon: 'check-circle', color: 'green', label: 'Ativo' },
        submitted: { icon: 'x-circle', color: 'blue', label: 'Fechado' },
        rejected: { icon: 'bookmark-x', color: 'red', label: 'Rejeitado' },
        approved: { icon: 'bookmark-check', color: 'green', label: 'Aprovado' },
        default: { icon: 'list-check', color: 'green', label: '' },
      },
      dimensions: {},
      svg: null,
      report: null,
      quiz: null,
      error: null,
      success: null,
      pdfLoad: null,
      selected: [1, 1, 1, 1, 1, 1],
      choices: [
        {
          label: 'Estratégia e Organização',
          value: {
            label: {
              1: '1', 2: '2', 3: '3', 4: '4', 5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Infraestrutura Inteligente',
          value: {
            label: {
              1: '1', 2: '2', 3: '3', 4: '4', 5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Operações Inteligentes',
          value: {
            label: {
              1: '1', 2: '2', 3: '3', 4: '4', 5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Produtos Inteligentes',
          value: {
            label: {
              1: '1', 2: '2', 3: '3', 4: '4', 5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Serviços Baseados em Dados',
          value: {
            label: {
              1: '1', 2: '2', 3: '3', 4: '4', 5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Recursos Humanos',
          value: {
            label: {
              1: '1', 2: '2', 3: '3', 4: '4', 5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
      ],
      timeout: null,
      svgResult: null,
      dimensionsResult: null,
      themesResult: null,
      newPdfMessage: null,
      newPdfMessageError: false,
      global: null,
      themes: null,
      generating: false,
      generated: false,
      loading: true,
      showCleanedDim: false,
      showCleanedDim2: false,
      showCleanedDim4: false,
    };
  },
  watch: {
    mutableQuizzes() {
      this.canOpenNewQuiz = true;
      if (this.mutableQuizzes.length > 0) {
        this.mutableQuizzes.forEach((element) => {
          if (element.quiz.status === 'open') {
            this.canOpenNewQuiz = false;
          }
        });
      }
    },
    quizzes(newValue) {
      this.mutableQuizzes = newValue;
    },
  },
  async created() {
    if (window.location.href.includes('localhost') || window.location.href.includes('shift2future.pt:8080')) {
      this.devOrUat = true;
    } else {
      this.devOrUat = true;
    }
    if (!this.$store.getters.state.loggedIn) {
      this.$router.replace({ name: 'auth' });
    } else {
      this.$store.commit('setFaultQuestions', null);
      await get('quiz', {})
        .then((response) => {
          if (response.data) {
            this.mutableQuizzes = response.data;
            if (this.mutableQuizzes.length === 0) {
              this.startQuiz();
            }
          }
        });
      await get('dimension', {})
        .then((response) => {
          if (response.data) {
            this.dimensions = response.data;
          }
        });
      await get('theme', {}).then((response) => {
        this.themes = response.data;
      });

      this.loading = false;
    }
  },
  methods: {
    async downloadReport(report, filename) {
      const linkSource = `data:application/${filename.split('.')[-1]};base64,${report}`;
      const downloadLink = document.createElement('a');
      const fileName = filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    getStatus(quiz) {
      if (quiz.status === 'open') {
        return 'open';
      } if (!(quiz.result === 1 || quiz.result === 2) && quiz.status === 'submitted') {
        return 'submitted';
      } if (quiz.result === 2) {
        return 'rejected';
      } if (quiz.result === 1) {
        return 'approved';
      }
      return 'default';
    },
    async openFuture(quiz, cleaned = false) {
      this.quiz = quiz;
      this.showCleanedDim = false;
      this.showCleanedDim2 = false;
      this.showCleanedDim4 = false;
      this.$store.commit('setQuiz', quiz);
      await post('score', {
        quiz: this.quiz,
        cleaned,
      }).then(async (response) => {
        this.dimensionsResult = response.data.dimensions;
        this.themesResult = response.data.themes;
        this.global = response.data.global;
        this.svgResult = generateChartSite(
          response.data.themes,
          response.data.dimensions,
          response.data.global,
          [0, 0, 0, 0, 0, 0],
          response.data.kos,
        ).innerHTML;
        this.$bvModal.show('future');
      }).catch((error) => {
        if (error.status === 409) {
          if (error.data.error === 'Fault Geral Questions.') {
            this.$bvModal.hide('future');
            this.$store.commit('setFaultGeralQuestions', true);
            this.$router.push({
              name: 'company_quiz',
              params: {
                id: this.$store.getters.quiz,
              },
            });
          } else if (error.data.error === 'Conflit. Cleaned dimension') {
            this.$bvModal.show('future');
            this.showCleanedDim = true;
            this.showCleanedDim2 = error.data.cleanedDim2;
            this.showCleanedDim4 = error.data.cleanedDim4;
          } else {
            this.$bvModal.hide('future');
            this.allResponses = true;
            this.$store.commit('setFaultQuestions', error.data.questions);
            this.$router.push({ name: 'quiz' });
            this.$store.commit('setDisabled', false);
            this.$store.commit('setQuiz', quiz);
          }
        } else {
          this.$bvModal.hide('future');
          this.error = true;
        }
      });
    },
    async getChoice(value) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = await setTimeout(async () => {
        await post('future', {
          quiz_id: this.quiz,
          values: value,
        });
      }, 500);
    },
    setConsultor(quiz) {
      this.quiz = quiz;
      this.$bvModal.show('consultor');
    },
    /*     async getReport(quiz) {
      await get(`score/${quiz}`, {})
        .then((response) => {
          this.report = response.data;
          this.$bvModal.show('report');
        }).catch((error) => {
          console.log(error);
        });
    }, */

    goToReport(id) {
      this.setState(3);
      this.$store.dispatch('setReportQuiz', id);
      /*  this.$router.push({
        name: 'report',
        params: {
          id,
        },
      }); */
    },

    startQuiz() {
      post('quiz', {})
        .then(async (response) => {
          await post(`quiz/company/${response.data.id}`, {})
            .then(() => {
              this.goToCompanyQuestions(response.data.id);
            });
        });
    },
    goToCompanyQuestions(id) {
      this.$store.commit('setDisabled', false);
      this.$store.commit('setQuiz', id);
      this.$router.push({
        name: 'company_quiz',
        params: {
          id,
        },
      });
    },
    goToQuiz(id) {
      this.$store.commit('setDisabled', false);
      this.$store.commit('setQuiz', id);
      this.$router.push({ name: 'quiz' });
    },
    goToQuizDisabled(id) {
      require('dotenv').config();

      this.$store.commit('setDisabled', true);
      this.$store.commit('setQuiz', id);
      this.$router.push({ name: 'quiz' });
    },
    async generatePdf(id) {
      this.newPdfMessageError = false;
      this.newPdfMessage = 'O seu relatório está a ser gerado. Receberá um email quando o relatório estiver pronto!';
      post(`generate-pdf/${id}`, {}).then((response) => {
        this.newPdfMessageError = false;
        this.newPdfMessage = 'O seu relatório está pronto!';
      }).catch((error) => {
        this.newPdfMessage = 'Ocorreu um erro a gerar o relatório. Por favor tente mais tarde.';
        this.newPdfMessageError = true;
      });
    },
    async submitQuiz(id) {
      this.pdfLoad = false;
      this.success = null;
      this.error = null;
      this.generating = true;
      this.generated = false;
      await post('score', {
        quiz: id,
      }).then(async (response) => {
        /* const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'file.pdf');
        document.body.appendChild(fileLink);

        fileLink.click(); */
        this.svg = generateChartSite(
          response.data.themes,
          response.data.dimensions,
          response.data.global,
          response.data.future,
          response.data.kos,
        ).innerHTML.replaceAll('textpath', 'textPath').replaceAll('startoffset', 'startOffset');
        await put(`/quiz/${id}`, {
          svg: this.svg,
          status: 'submitted',
        }).then(async () => {
          this.$bvModal.hide('future');
          this.selected = [0, 0, 0, 0, 0, 0];
          this.success = true;

          setTimeout(() => {
            this.$store.dispatch('dashboard/setState', 3);
            this.$router.push({ name: 'diagnostic' });
          }, 5000);

          await get(`/score/pdf/${id}`, {});

          await get('quiz', {})
            .then((response) => {
              if (response.data) {
                this.mutableQuizzes = response.data;
              }
              this.generating = false;
              this.generated = true;
            })
            .catch(() => {
              this.generating = false;
              this.generated = false;
              this.error = true;
            });
        }).catch(() => {
          this.generating = false;
          this.error = true;
        });
      }).catch((error) => {
        this.generating = false;
        this.generated = false;
        this.$bvModal.hide('future');
        if (error.status === 409) {
          if (error.data.error === 'Fault Geral Questions.') {
            this.$router.push({
              name: 'company_quiz',
              params: {
                id: this.$store.getters.quiz,
              },
            });
          } else {
            this.$store.commit('setFaultQuestions', error.data.questions);
            this.goToQuiz(id);
          }
        } else {
          this.error = true;
        }
      });
    },
  },
};
</script>
