<template>
  <b-modal
    id="consultor"
    centered
    hide-footer
    size="lg"
    header-class="p-2 border-bottom-0"
    footer-class="p-2 border-top-0"
  >
    <b-overlay :show="overlay">
      <div>
        <div
          class="title"
        >
          Email do facilitador
        </div>
        <div class="mb-4 text-center subtitle">
          Ao adicionar um facilitador a este questionário, estará a consentir que o facilitador tenha acesso às respostas dadas ao questionário.
        </div>
        <div
          v-if="error || success"
          class="feedback-row "
        >
          <div
            v-if="error"
            class="error-danger"
          >
            {{ errorMessage }}
          </div>
          <div
            v-if="success"
            class="error-success"
          >
            Aguarde que o facilitador aceite o seu pedido!
          </div>
        </div>
        <div
          class="description"
        />
        <b-form
          @submit="onSubmit"
          @reset="onReset"
        >
          <b-form-group
            id="input-group-1"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Inserir email"
              required
            />
          </b-form-group>
          <div class="buttons">
            <button
              type="submit"
              class="button primary"
              variant="primary"
            >
              Confirmar
            </button>
            <b-button
              type="reset"
              class="button secondary"
              variant="primary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import { get, post } from '../../services/api/index.js';

export default {
  name: 'ModalConsultor',
  props: {
    quiz: Number,
  },
  data() {
    return {
      success: false,
      error: false,
      errorMessage: null,
      form: {
        email: null,
      },
      overlay: false,
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.overlay = true;
      this.success = false;
      this.error = false;
      this.errorMessage = null;
      await post('quiz_facilitator', {
        facilitator: this.form.email,
        quiz: this.quiz,
      })
        .then((response) => {
          this.success = true;
        }).catch((error) => {
          this.error = true;
          if (error.data.error === 'This email does not belong to an facilitator.') {
            this.errorMessage = 'Este email não pertence a nenhum facilitador registado na plataforma SHIFT2Future!';
          } else if (error.data.error === 'Already exists for this quiz') {
            this.errorMessage = 'Já existe um consultor associado a este questionário ou com aceitação pendente!';
          } else if (error.data.error === 'This facilitator belogns to compnay of quiz.') {
            this.errorMessage = 'O facilitator requerido pertence à mesma empresa do questionário!';
          } else {
            this.errorMessage = 'Ocorreu um erro. Por favor, tente mais tarde!';
          }
        });
      this.overlay = false;
    },
    onReset(event) {
      event.preventDefault();
      this.form.email = '';
    },
  },
};
</script>
